.podcast {
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.podcast-banner {
  height: 15rem;
  background: url(../../img/podcast.jpg);
  width: 100%;
  background-position: cover;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  color: $white;
  text-transform: capitalize;

  h1 {
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
  }
}
.podcast-title {
  color: $secondary;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2rem;
    text-transform: capitalize;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.podcast-container {
  width: 80%;
  margin: 2rem auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}
.podcast-item {
  width: 30%;
  height: 100%;
  padding: 0rem ;
  margin: 2rem 1rem;
  position: relative;
  border: none;
  box-shadow: $box-shadow;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.podcast-itemmm {
    width: 30%;
    height: 100%;
    padding: 0rem ;
    margin: 2rem 1rem;
    position: relative;
    border: none;
    background: $white;
    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5), 7px 7px 12px rgba(223, 218, 218, 0.15);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
.podcast-details {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.podcast-name {
  text-transform: capitalize;
  text-align: start;
}
.podcast-icon {
  margin-left: 10px;
  align-items: flex-end;
  text-align: end;
  justify-content: end;
  color: $secondary;
  cursor: pointer;
  font-size: 1.2rem;
}
.container2 {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.column1 {
  display: flex;
  flex-direction: column;
  width: 40%;
  position: relative;
  align-items: center;

  p {
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 23px;
  }
}
.pod-item {
  width: 25rem;
  padding: 0rem 0;
  margin: 2rem 1rem 1rem 1rem;
  position: relative;
  border: none;
  box-shadow: $box-shadow;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.image-podcast {
  width: 100%;
  height: 50vh;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* RESPONSIVE */
/* RESPONSIVE */

@media (max-width: 64em) {
  .podcast-itemmm {
    width: 40%;
  }
  .column1 {
    width: 100%;
  }
  .podcast-banner {
    font-size: 1.8rem;
    height: 10rem;
  }
}

@media (max-width: 48em) {
  .column1 {
    width: 100%;
  }
  .podcast-item {
    width: 100%;
  }
  .podcast-itemmm {
    width: 100%;
  }
  .pod-item {
    width: 100%;
  }
  .podcast-banner {
    font-size: 1.5rem;
    height: 8rem;
  }
}