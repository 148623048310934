@keyframes bounce {
  from {
    transform: translate(-50%) scale(0.5);
  }
  to {
    transform: translate(-50%) scale(1);
  }
}

.roadmap {
  min-height: 100vh;
  width: 100vw;
  position: inherit;
}
.container-road {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.svg-container {
  display: flex;
  // justify-content: center;
  // align-items: center;
}
.vector-container {
  position: absolute;
  top: 0.5rem;
  // left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 100%;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
    color: $black;
  }
}
.ball {
  position: absolute;
  top: 0rem;
  // left: 50%;
  transform: translate(-50%);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $black;
  animation: bounce 0.5s linear infinite alternate;
}
.road-items {
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.road-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.container-item {
  width: 100%;
  height: 40vh;
  padding: 1rem;
  
}
.box-road {
  height: 100%;
  width: 100%;
  padding: 1rem;
  position: relative;
  text-align: start;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.subtitle-road {
  display: block;
  font-size: 1.7rem;
  margin: 0.5rem;
  text-transform: capitalize;
  font-weight: 600;
  color: $secondary;
}
.subtitle-light-road {
  display: block;
  width: 100%;
  margin: 0.5rem;
  font-weight: 400;
  font-size: 1.1rem;
}
.roasd {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  align-items: center;
  width: 85%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

/* RESPONSIVE */
/* RESPONSIVE */

@media (max-width: 60em) {
  .typewriter-road {
    font-size: 1.8rem;
  }
  .container-item {
    width: 100%;
    height: 100%;
    padding: 0rem;
  }
  .roasd {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0.5rem 0;
  }
  .box-road {
    text-align: center;
    padding: 0rem;
    margin: 1rem 0rem;
  }
  .subtitle-road {
    font-size: 1.5rem;
    width: 100%;
  }
}

@media (max-width: 40em) {
  .typewriter-road {
    font-size: 1.4rem;
  }
  .subtitle-road {
    font-size: 1.3rem;
    width: 100%;
  }
}
