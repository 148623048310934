.whatwedo {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.what-banner {
  height: 15rem;
  background: url(https://images.pexels.com/photos/586744/pexels-photo-586744.jpeg?cs=srgb&dl=pexels-tejas-prajapati-586744.jpg&fm=jpg);
  width: 100%;
  background-position: cover;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: $white;
  text-transform: capitalize;

  h1 {
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
  }
}
.what-container {
  text-align: center;
  align-items: center !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.whatwedo-inner-cn {
  text-align: center;
  align-items: center !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 80%;
}
.what-card {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    font-size: 1.3rem;
    margin: 0.5rem 0;
    text-align: start;
  }
}
.what-svg {
  width: 10rem;
  height: 10rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 1rem;
}

/* RESPONSIVE */

@media (max-width: 64em) {
   .what-banner {
    font-size: 1.7rem;
    height: 10rem;
   }
  
  }
  
  @media (max-width: 48em) {
     .what-card {
    p {
      font-size: 1.1rem;
      text-align: center;
    }
  }
    .what-banner {
        font-size: 1.5rem;
    height: 8rem;
    }
  }
  @media (max-width: 40em) {
    .what-banner {
      font-size: 1.2rem;
     }
  }