.maiden {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.maiden-banner {
  height: 15rem;
  background: url(https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?cs=srgb&dl=pexels-asad-photo-maldives-3293148.jpg&fm=jpg);
  width: 100%;
  background-position: cover;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 2rem;
  color: $white;
  text-transform: capitalize;

  h1 {
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    text-transform: capitalize;
  }
}
.maiden-container {
  width: 80%;
  margin: 2rem auto;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;

  img {
    height: 70%;
  }

  h3 {
    margin: 1rem 0;
  }
  h1 {
    text-transform: capitalize;
    margin: 1rem 0;
    font-size: 2.2rem;
  }
  p {
    margin: 1rem 0;
    font-size: 1.2rem;
    font-weight: lighter;
    line-height: 30px;
  }
}

/* RESPONSIVE */

@media (max-width: 64em) {
  .maiden-banner {
    font-size: 1.4rem;
    height: 10rem;
  }
  .maiden-container {
    h1 {
      font-size: 1.8rem;
    }
  }
}
@media (max-width: 48em) {
  .maiden-banner {
    font-size: 1rem;
    height: 8rem;
  }
  .maiden-container {
    h1 {
      font-size: 1.6rem;
    }
  }
}
@media (max-width: 40em) {
  .maiden-banner {
    font-size: .8rem;
  }
}
