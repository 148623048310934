.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
        align-items: center;
}
.footer-banner {
    width: 70%;
    height: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $linear ;
    top: 7rem;
    z-index: 5;

    h1 {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 2.4rem; 
}
    p {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
        font-size: 1.2rem;
    }
    button {
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}
.footer-button {
    cursor: pointer;
    width: 10rem;
    background: $white;
    color: $black;
    padding: 15px;
    border: none;
    border-radius: 8px;
    text-align: center;
    transition: all 0.2s ease;
    font-size: 15px;
    margin-top: 2rem;
}
.footer-container {
    width: 100%;
    background: $smoky-black;
    color: $white;
    min-height: 30rem;
}
.footer-items {
    width: 80%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 7rem;
    position: relative;
}
.footer-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22%;
    text-align: center;

    p {
        margin: 1rem 0;
    }
}
.icons {
    margin: 0 .2rem;

    &:hover {
        color: $secondary;
    }
}
.footer-middle {
    width: 20%;
}
.icons-middle {
    background: $primary;
    color: $smoky-black;
    padding: 3px;
    margin-right: 10px;
}
.list-title {
    font-size: 1.3rem;
    text-transform: capitalize;
    color: $primary;
    margin: 1rem 0;
}
.lit {
    font-size: 1.1rem;
    margin: .5rem 0;
}
.list {
    text-decoration: none;
    list-style: none;
    font-size: 1.1rem;
    margin: .4rem 0;
    width: fit-content;
    cursor: pointer;

    &::after {
        content: '';
        display: block;
        width: 0%;
        height: 2px;
        background: $primary;
        transition: width 0.3s ease;
    }
    &:hover::after {
        width: 100%;
    }
}
.footer-right {
    width: 30%;
}
.list-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    list-style: none;
    grid-row-gap: 0rem;
    grid-column-gap: 1rem;
    white-space: nowrap;
}
.bottom {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   top: 9rem;
   position: relative;
   border-top: 1px solid $white;
   color: $primary;
}
.footer-mobile {
    display: none;
}


  
/* RESPONSIVE */

@media (max-width: 64em) {
    .footer-banner {
        height: 15rem;
        top: 5rem;
        width: 80%;

        h1 {
            font-size: 1.3rem;
        }
        p {
            font-size: 1rem;
            width: 70%;
        }
    }
    .footer-button {
        width: 7rem;
    font-size: 15px;
    margin-top: 1rem;
    }
    .footer-middle {
        width: 30%;
    }
    
    
}
@media (max-width: 48em) {
    .footer-container {
        height: fit-content;
        width: 100%;
    }
    .footer-items {
        display: none;
    }
    .footer-banner {
        display: none;
    }
    .bottom {
        display: none;
    }
    .footer-mobile {
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    .mobile-links{
        display: flex;
        flex-direction: column;
        color: $primary-light;
        border-top: 1px solid $grey;
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-top: 2rem;

        a {
        margin-bottom: 1rem;
    }
    }
    
    .footer-mobile-icons {
        color: $grey;

        a{
            margin-right: 1rem;

            a:hover {
            color: $primary-light;
        }
        }
    }
    .footer-mobile-first-section {
        width: 60%;
        margin-bottom: 2rem;
    }
    .footer-mobile-second-seec {
        h3 {
            font-size: 20px;
        }
    }
}