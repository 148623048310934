.team {
  min-height: 100vh;
  width: 100%;
  position: relative;
  font-family: Helvetica;
}
.team-banner {
  height: 15rem;
  background: url(../../img/team.jpg);
  width: 100%;
  background-position: cover;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  h1 {
    color: $white; 
    font-size: 4rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
  }
}
.container-show {
  width: 90%;
  margin: 2rem auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.show-item {
  width: 20rem;
  height: 70vh;
  padding-bottom: 1rem;
  margin: 2rem 1rem;
  position: relative;
  border: 2px solid $white;
  box-shadow: $box-shadow;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.names {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin: 1rem 0;
}
.position {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  margin: 0 1rem;
  font-weight: 400;
}
.image-contain {
  width: 100%;
  height: 80%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    object-fit: cover;
  }
}

/* RESPONSIVE */

@media (max-width: 64em) {
  .container-show {
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .team-banner {
    height: 10rem;
    font-size: 3rem;
  }
}

@media (max-width: 48em) {
  .container-show {
    width: 80%;
    justify-content: center;
  }
  .show-item {
    width: 100%;
    height: 70vh;
  }
  .image-contain {
    width: 100%;
    height: 80%;
  }
  .team-banner {
    height: 8rem;
    h1 {
      font-size: 2.6rem;
    }
  }
}

@media (max-width: 40em) {
  .team-banner {
    h1 {
      font-size: 2rem;
    }
  }
}
