.home {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.first-section {
  height: calc(100vh - 80px);
  left: 0;
  top: 0;
  width: 100%;
  background: url(../../img/homebg.png);
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.outer {
  position: absolute;
  height: calc(100vh - 80px);
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.first-details {
  left: 50%;
  top: 50%;
  width: 70%;
  color: $white;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;

  h1 {
    font-size: 3.5rem;
    text-transform: capitalize;
    font-family: "EB Garamond", serif;
  }
  p {
    margin: 1rem 0;
    font-weight: lighter;
  }
}
.box-home {
  display: flex;
  justify-content: center;
  // gap: 1rem;
  margin-top: 3rem;
}
.btn-home {
  cursor: pointer;
  width: 10rem;
  background: transparent;
  color: white;
  padding: 15px;
  border: 2px solid $secondary;
  border-radius: 8px;
  text-align: center;
  transition: all 0.2s ease;
  font-size: 16px;
  margin-right: 1rem;
  height: 3rem;
  display: flex;
  justify-content: center;
}
.grid-container {
  width: 80%;
  margin: 4rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80vh;
}
.grig-contained {
  width: 100%;
  background: $secondary-light;
}
.grid-contained {
  width: 80%;
  margin: 4rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 90vh;
}
.box-grid {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    text-transform: capitalize;
    font-family: "EB Garamond", serif;
    text-align: start;
  }
  p {
    margin: 1.5rem 0;
    font-size: 1.3rem;
    font-weight: lighter;
    text-align: start;
    color: $grey;
  }
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
 
  .image {
    width: 10rem;
    height: 25vh;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}
.podcast-sect {
  width: 100%;
  background: $cream-color;
  min-height: 65vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 2rem;

  h1 {
    font-size: 3rem;
    text-transform: capitalize;
    font-family: "EB Garamond", serif;
  }
  p {
    font-size: 1.3rem;
    margin: 1rem;
    color: $grey;
  }
}
.podcast-sectext {
  position: relative;
  top: 2rem;
}
.counter {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
.counter-content {
  width: 20%;

  h3 {
    font-size: 1.25rem;
    color: $grey;
    font-weight: lighter;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-family: "EB Garamond", serif;
  }
}
.count {
  font-size: 2rem;
}
.articles {
  width: 100%;
  min-height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}
.gradient {
  width: 100%;
  height: 8rem;
  background: $linear;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: $white;
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  font-family: "EB Garamond", serif;
}
.news {
  width: 90%;
  display: flex;
  position: relative;
}
.news-box {
  width: 50%;
  padding: 0rem 0;
  margin: 3rem 1rem;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;

  img {
    height: 50vh;
    width: 100vw;
    object-fit: cover;

    @media screen and (max-width: 64em) {
    }
  }
}
.news-box1 {
  bottom: 3rem;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: fit-content;
  background: $white;
  box-shadow: $box-shadow;

  h1 {
    font-size: 1.2rem;
    width: 70%;
    margin: 1rem 0rem 1rem 1rem;
    text-transform: capitalize;
    line-height: 30px;
  }
  p {
    margin: 1rem;
    font-weight: lighter;
    color: $grey;
    line-height: 24px;
  }
}
.btn-news {
  cursor: pointer;
  width: 7rem;
  background: transparent;
  color: white;
  padding: 9px;
  border: none;
  border-radius: 5px;
  text-align: center;
  transition: all 0.2s ease;
  font-size: 12px;
  margin: 1rem 0rem 1rem 1rem;
}
.hhs {
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.news-bottom {
  margin: 2rem 0rem 2rem 1rem;
  display: flex;
  align-items: center;
  font-size: medium;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50px;
    margin-right: 0.5rem;
  }
  h4 {
    font-weight: 300;
    line-height: 25px;
  }
}

/* RESPONSIVE */
/* RESPONSIVE */

@media (max-width: 64em) {
  .first-details {
    width: 80%;
    h1 {
      font-size: 2.5rem;
    }
  }
  .btn-home {
    width: 7.5rem;
  }
  .grid-container {
    flex-direction: column;
    justify-content: center;
  }
  .grid-contained {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .box-grid {
    width: 100%;
    align-items: center;
    h1 {
      font-size: 2rem;
      margin-top: 3rem;
      text-transform: capitalize;
      text-align: center;
    }
    p {
      margin: 1.5rem 0;
      font-size: 1.2rem;
      text-align: center;
    }
  }
  .podcast-sectext {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
  .gradient {
    font-size: 1.5rem;
  }
  .grid1 {
    .image {
      width: 15rem;
      height: 20vh;
    }
  }
  .image-pen {
    width: 15rem;
    height: 20vh;
    font-weight: 600;
  }

  .news {
    flex-direction: column;
    margin-top: 1rem;
  }
  .news-box {
    width: 90%;
    margin: 0rem 1rem;
  }
  .news-box1 {
    width: 90%;
    height: auto;
    bottom: 5rem;
  }
}

// 48EM
// 48EM

@media (max-width: 48em) {
  .first-details {
    h1 {
      font-size: 2rem;
    }
    h3 {
      font-size: 0.8rem;
    }
  }
  .grid1 {
    .image {
      width: 7rem;
      height: 17vh;
    }
  }
  .image-pen {
    font-weight: 500;
  }
  .image-penlogo {
    font-weight: 500;
  }
  .image-logoo {
    img {
      width: 100% !important;
    }
  }

  .box-grid {
    h1 {
      font-size: 1.8rem;
      margin-top: 2rem;
      text-transform: capitalize;
    }
    p {
      margin: 1rem 0;
      font-size: 1.1rem;
    }
  }
  .podcast-sectext {
    h1 {
      font-size: 1.7rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .gradient {
    font-size: 1.3rem;
  }
  .counter-content {
    h3 {
      font-size: 1rem;
    }
  }
  .count {
    font-size: 1.6rem;
  }
  .news-box1 {
    bottom: 3rem;
  }
  .news-box {
    margin: 1rem 1rem;
  }
}

.image-pen {
  background: $secondary;
  color: $white;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.image-penlogo {
  background: $primary-light;
  color: $white;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  img {
    width: 50% !important;
    height: 40% !important;
  }
}
.image-logoo {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    rgba($primary-light, 0.5),
    rgba($secondary, 0.3)
  );

  img {
    width: 50% !important;
    height: 60% !important;
  }
}

// 25EM
// 25EM

@media (max-width: 25em) {
  .btn-home {
    width: 5.5rem;
  }
}
