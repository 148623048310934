.volunteer {
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.volunteer-banner {
  height: 15rem;
  background: $linear;
  width: 100%;
  color: $white;
  text-align: center;
  justify-content: center;
  position: relative;
  display: flex;
  font-size: 2.5rem;

  h1 {
    margin-top: 2.2rem;
  }
}
.volunteer-container {
  width: 70%;
  margin: 2rem auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  bottom: 7rem;
  position: relative;
  background: $white;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.25), 0px 7px 12px rgba(185, 184, 184, 0.12);
}
.volunteer-form {
  width: 70%;

  h2 {
    margin: 2rem 0;
    font-weight: 300;
  }

  label {
    font-size: 1.2rem;
  }

  input {
    display: block;
    width: 100%;
    padding: 0 20px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    font-size: 15px;
    letter-spacing: 0.1px;
    background: none;
    border: 1px solid lightgrey;
    color: $grey;
  }

  select {
    display: block;
    width: 100%;
    padding: 0 20px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    font-size: 15px;
    letter-spacing: 0.1px;
    background: none;
    border: 1px solid lightgrey;

    option {
      margin: 2rem 0;
      padding: 20px 0;
    }
  }

  textarea#message {
    min-height: 120px;
    background: none;
    padding: 0 20px;
    width: 100%;
    margin: 20px 0;
    line-height: 46px;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
    background: none;
    letter-spacing: 0px;
    border: 1px solid lightgrey;
  }
}
.department {
  margin: 1rem 0;
}
.volunteer-form-flex {
  display: flex;
  justify-content: space-between;
}
.volunteer-flex-1 {
  width: 45%;
}
.volunteer-flex-2 {
  width: 45%;
}
.volunteer-button {
  background: $secondary;
  color: $white;
  width: 100%;
  margin-top: 1rem ;
  font-size: 1.2rem;
  height: 3.5rem;
  border: none;
  cursor: pointer;
}

/* RESPONSIVE */
/* RESPONSIVE */

@media (max-width: 64em) {
  .volunteer-banner {
    font-size: 1.7rem;
    height: 10rem;
  }
  .volunteer-form {
    h2 {
      font-size: 1.2rem;
    }
  }
  .volunteer-container {
      bottom: 4rem;
  }
}

@media (max-width: 48em) {
  .volunteer-banner {
    font-size: 1.5rem;
  }
  .volunteer-container {
    width: 100%;
    box-shadow: none;
  }
  .volunteer-form {
    width: 90%;
  }
  .volunteer-form-flex {
    flex-direction: column;
  }
  .volunteer-flex-1 {
    width: 100%;
  }
  .volunteer-flex-2 {
    width: 100%;
  }
}

@media (max-width: 40em) {
    .volunteer-banner {
      font-size: 1.2rem;
     height: 9rem;
  }
}
  