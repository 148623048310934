.ourcause {
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.about-banner {
  height: 15rem;
  background: url(https://images.pexels.com/photos/1329711/pexels-photo-1329711.jpeg?cs=srgb&dl=pexels-tan-danh-1329711.jpg&fm=jpg);
  width: 100%;
  background-position: cover;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: $white;
  text-transform: capitalize;

  h1 {
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
  }
}
.about-container {
  width: 80%;
  margin: 4rem auto;
  display: flex;
  justify-content: space-between; 
  align-items: center;
}
.about-contained {
  width: 80%;
  margin: 4rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box {
  width: 45%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 1rem 0;
    font-size: 1.2rem;
    font-weight: lighter;
  }
}
.box-middle {
  width: 45%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 1rem 0;
    font-size: 1.2rem;
  }
}

/* RESPONSIVE */
/* RESPONSIVE */

@media (max-width: 64em) {
  .about-banner {
    font-size: 1.7rem;
    height: 10rem;
  }
  .about-container {
    flex-direction: column;
    margin: 2rem auto;
  }
  .box {
    width: 100%;
  }
  .about-contained {
    flex-direction: column-reverse;
    margin: 2rem auto;
  }
  .box-middle {
    width: 100%;
  }
}

@media (max-width: 48em) {
  .about-banner {
    font-size: 1.5rem;
    height: 8rem;
  }
}

@media (max-width: 40em) {
  .about-banner {
    font-size: 1.2rem;
  }
}