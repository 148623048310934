* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // -webkit-tap-highlight-color: transparent;
    user-select: none;
}
input, textarea {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

body {
    font-family: 'Helvetica '; 
}
// html {
//     font-size: 16px;
// }
body::-webkit-scrollbar{ 
    width: 10px;
}
body::-webkit-scrollbar-thumb{
    background-color: $primary;
    border-radius: 50px;
}
body::-webkit-scrollbar-track{
    background-color: $grey;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: unset;
}

link {
    text-decoration: none;
}

img {
    max-width: 100%;
}
h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}
.spinner {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
